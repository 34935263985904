import { Button } from "@chakra-ui/react"

export default function HeroButton(props) {
  const { buttonName, bgColor } = props
  return (
    <Button
      bg={bgColor ? bgColor : "#f05605"}
      color="#fff"
      fontSize="0.875rem"
      variant="no-hover"
      fontWeight="500"
      border-color="#f05605"
      padding="1rem 2rem"
      height="44px"
      onClick={(event) => {
        if (!props?.ButtonClick) return null
        props?.ButtonClick()
      }}
    >
      {buttonName.toUpperCase()}

    </Button>
  )
}
