import { useEffect, Fragment, useState, useContext } from "react"
import {
  Box,
  Flex,
  Text,
  Heading,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Link,
} from "@chakra-ui/react"
import { SimpleGrid, Stat, StatLabel } from "@chakra-ui/react"
import AOS from "aos"
import "aos/dist/aos.css"
import { Container } from "@chakra-ui/react"
import Image from "next/image"
import HeroButton from "../components/buttons/HeroButton.js"
import Card from "../components/card/Card.js"
import CardBody from "../components/card/CardBody.js"
import IconBox from "../components/icons/IconBox"
import hero from "../public/img/hero-banner.webp"
import sellersLeadsIcon from "../public/img/pb-icon-1.png"
import sellersIntelligenceIcon from "../public/img/pb-icon-2.png"
import sellersTopSellerListsIcon from "../public/img/pb-icon-3.png"
import sellersChromeExtensionIcon from "../public/img/pb-icon-4.png"
import sellersLeadsTab from "../public/img/seller-leads-home.svg"
import sellersIntelligenceTab from "../public/img/seller-intelligence-report-home.webp"
import sellersTopSellerListsTab from "../public/img/top-seller-list-home.webp"
import advancedSellerSearch from "../public/img/seller-leads-advanced.png"
import featuresDashboard from "../public/img/features-image2.png"
import featuresRanking from "../public/img/features-image3.png"
import { CheckIcon } from "@chakra-ui/icons"
import styles from "../styles/Index.module.css"
import { DomainContext } from "../components/context/DomainContext.js"
import Meta from "../components/meta/Meta.js"
import SellerSearchAutoComplete from "../components/inputs/SellerSearchAutoComplete"
import { SUBSCRIBE_URL } from "../utils/Constants"
import ModalIframe from "../components/modalIframe/ModalIframe.js"
import NextLink from "next/link"

export default function Index() {
  const [subscribeModalShow, setSubscribeModalShow] = useState(false)
  const [iframeUrl, setIframeUrl] = useState("")
  const [heading, setHeading] = useState("")
  const [height, setHeight] = useState("480px")
  const domainContextValue = useContext(DomainContext)
  const textColor = useColorModeValue("gray.500", "white")
  const accordionImageList = [
    sellersLeadsTab,
    sellersIntelligenceTab,
    sellersTopSellerListsTab,
  ]
  const [currentAccordianIndex, setCurrentAccordianIndex] = useState(0)
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#243e63" : "#718096",
      width: "100%",
      maxWidth: "400px",
      borderRadius: "7px",
      overflow: "hidden",
      marginBottom: "5px",
      padding: "6px 10px 6px 10px",
      cursor: "pointer"
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
      display: "flex",
      flexDirection: "row",
      maxWidth: "400px",
      boxShadow: "0 3px 6px 0 rgb(140 152 164 / 25%)",
      backgroundColor: "#fff",
      borderRadius: "7px",
      overflow: "hidden",
      height: "50px"
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none"
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
      maxWidth: "400px",
      boxShadow: "0 3px 6px 0 rgb(140 152 164 / 25%)",
      backgroundColor: "#fff",
      borderRadius: "7px",
      overflow: "hidden",
      border: "none",
      padding: "5px 5px 5px 5px",
      height: "200px",
      fontSize: "14px",
      zIndex: "1000 !important",
      marginTop: "-10px"
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = "opacity 300ms"
      return { ...provided, opacity, transition }
    }
  }

  useEffect(() => {
    AOS.init({
      disable: function () {
        var maxWidth = 640
        return window.innerWidth < maxWidth
      },
      duration: 2000,
    })
  }, [domainContextValue.code])

  const subscribeModalToggle = () => {
    setIframeUrl(SUBSCRIBE_URL)
    setHeading("Subscribe to")
    setHeight("480px")
    setSubscribeModalShow(!subscribeModalShow)
  }

  const SearchValue = () => {

  }
  return (
    <Fragment>
      <Meta
        pageName={"Amazon Sellers Directory: Seller Leads & Intelligence | TrackSellers"}
        description={"Explore over 5 million Amazon sellers database with TrackSellers. Get the right Amazon Seller Leads, Seller Intelligence, Top Sellers List & more."}
        hrefLink={"https://www.tracksellers.com/"}
      />
      <Box className={styles.HeaderBg} mt="0px">
        <Container maxW="container.xl" className={styles.ContainerBase}>
          <Flex
            className={styles.FlexHeader}
            pt={{ base: "80px", md: "80px", lg: "50px", xl: "50px" }}
            direction={{ base: "column", md: "column", lg: "row" }}
            h={{ base: "100%", md: "100%", lg: "100%", xl: "700px" }}
          >
            <Box
              className={styles.Box}
              mb={{ base: "30px", md: "50px" }}
              data-aos="fade-right"
              onClick={() => { }}
            >
              <Heading
                as={"h1"}
                className={styles.MainHeading}
                fontSize={{ base: "2.8rem", md: "2.8rem", lg: "3.3rem" }}
              >
                The Ultimate Amazon &nbsp;
                <Text className={styles.SubText} display={{ md: "flex" }}>
                  Seller Directory
                </Text>
              </Heading>
              <Text color={textColor} className={styles.TextDescription}>
                Leverage TrackSellers comprehensive database of 5 million
                Amazon sellers, explore rich seller profiles, generate
                targeted leads, and forge strategic partnerships to
                supercharge your e-commerce success.
              </Text>
              {/** Cuttom Button Component */}
              <Box
                display="flex"
              >
                <NextLink href={"/get-started"} passHref>
                  <Link mr="15px">
                    <HeroButton
                      buttonName="Get Started"
                    />
                  </Link>
                </NextLink>
              </Box>
              <Box paddingTop="20px" className={styles.AutoSearchBox}>
                <SellerSearchAutoComplete searchVal={SearchValue} instanceId={"indexSearch"} />
              </Box>
            </Box>
            <Box
              flex="1"
              position="relative"
              data-aos="fade-left"
              zIndex={{ base: "-100", md: "-100", lg: "initial", xl: "initial" }}
            >
              <Box maxW="550px" className={styles.HeroImageBox}>
                <Image src={hero} alt="hero" />
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box
        pt="30px" pb="30px"
        className={styles.AboutCardBox}
        maxWidth={{ base: "100%", md: "90%", lg: "80%" }}
      >
        <Container maxW="container.xl" justify="center">
          <Heading
            color="#243e63"
            fontSize={{ base: "2.8rem", md: "3rem", lg: "3rem" }}
            align="center"
            mb="10"
          >
            Benefits of Using Amazon Seller Directory
          </Heading>
          <SimpleGrid columns={{ sm: "1", md: "2" }} spacing="40px">
            <Card mb={{ lg: "0px" }} className={styles.AboutCard}>
              <Box className="circleIcon"></Box>
              <CardBody>
                <Flex
                  direction={{
                    base: "column-reverse",
                    md: "column-reverse",
                    lg: "row",
                  }}
                  align="start"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <Flex
                      alignItems={{ base: "start", md: "center", lg: "center" }}
                      mb={{ base: "15px", md: "20px", lg: "30px" }}
                      direction={{ base: "column", md: "row", lg: "row" }}
                    >
                      <IconBox
                        w={{
                          md: "80px",
                          lg: "100px",
                          xl: "100px",
                          "2xl": "100px",
                        }}
                      >
                        <Image
                          // boxSize={{ lg: "100%", xl: "100%", "2xl": "100px" }}
                          src={sellersLeadsIcon}
                          alt="hero"
                        />
                      </IconBox>
                      <StatLabel
                        className={styles.CardStatLabel}
                        paddingLeft={{ base: "0px", md: "30px", lg: "30px" }}
                      >
                        <Heading
                          as={"h3"}
                          color="#243e63"
                          mb={{ base: "5px", md: "10px", lg: "10px" }}
                          mt={{ base: "20px", lg: "0px" }}
                          fontSize={{
                            base: "1.5rem",
                            md: "1.4rem",
                            lg: "1.4rem",
                          }}
                          align="left"
                        >
                          Growth Platform
                        </Heading>

                        <Text className={styles.CardStatNumber}>
                          For Service & Software Providers
                        </Text>
                      </StatLabel>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text className={styles.CardPointText} color="gray.500">
                        Tailored lead generation strategies
                      </Text>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Access to an extensive Amazon seller database
                      </Text>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Enhance existing data offerings with Amazon seller information
                      </Text>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Increase the value and appeal of your data services
                      </Text>
                    </Flex>
                  </Stat>
                </Flex>
              </CardBody>
            </Card>
            <Card padding="22px" className={styles.AboutCard}>
              <CardBody>
                <Flex
                  direction={{
                    base: "column-reverse",
                    md: "column-reverse",
                    lg: "row",
                  }}
                  align="start"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <Flex
                      alignItems={{ base: "start", md: "center", lg: "center" }}
                      mb={{ base: "15px", md: "20px", lg: "30px" }}
                      direction={{ base: "column", md: "row", lg: "row" }}
                    >
                      <IconBox
                        w={{
                          md: "80px",
                          lg: "100px",
                          xl: "100px",
                          "2xl": "100px",
                        }}
                      >
                        <Image
                          // boxSize={{ lg: "100%", xl: "100%", "2xl": "auto" }}
                          src={sellersIntelligenceIcon}
                          alt="hero"
                        />
                      </IconBox>
                      <StatLabel
                        className={styles.CardStatLabel}
                        paddingLeft={{ base: "0px", md: "30px", lg: "30px" }}
                      >
                        <Heading
                          as={"h3"}
                          color="#243e63"
                          mb={{ base: "5px", md: "10px", lg: "10px" }}
                          mt={{ base: "20px", lg: "0px" }}
                          fontSize={{
                            base: "1.5rem",
                            md: "1.4rem",
                            lg: "1.4rem",
                          }}
                          align="left"
                        >
                          Comprehensive Seller Profiles
                        </Heading>

                        <Text className={styles.CardStatNumber}>
                          Competitive Intelligence
                        </Text>
                      </StatLabel>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        In-depth seller data and analytics
                      </Text>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Business entity information and location
                      </Text>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Product categories and brands
                      </Text>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Performance metrics and historical trends
                      </Text>
                    </Flex>
                  </Stat>
                </Flex>
              </CardBody>
            </Card>
            <Card padding="22px" className={styles.AboutCard}>
              <Box className="circleIcon"></Box>
              <CardBody>
                <Flex
                  direction={{
                    base: "column-reverse",
                    md: "column-reverse",
                    lg: "row",
                  }}
                  align="start"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <Flex
                      alignItems={{ base: "start", md: "center", lg: "center" }}
                      mb={{ base: "15px", md: "20px", lg: "30px" }}
                      direction={{ base: "column", md: "row", lg: "row" }}
                    >
                      <IconBox
                        w={{
                          md: "80px",
                          lg: "100px",
                          xl: "100px",
                          "2xl": "100px",
                        }}
                      >
                        <Image
                          // boxSize={{ lg: "100%", xl: "100%", "2xl": "auto" }}
                          src={sellersTopSellerListsIcon}
                          alt="hero"
                        />
                      </IconBox>
                      <StatLabel
                        className={styles.CardStatLabel}
                        paddingLeft={{ base: "0px", md: "30px", lg: "30px" }}
                      >
                        <Heading
                          as={"h3"}
                          color="#243e63"
                          mb={{ base: "5px", md: "10px", lg: "10px" }}
                          mt={{ base: "20px", lg: "0px" }}
                          fontSize={{
                            base: "1.5rem",
                            md: "1.4rem",
                            lg: "1.4rem",
                          }}
                          align="left"
                        >
                          Amazon Marketplace Insights
                        </Heading>

                        <Text className={styles.CardStatNumber}>
                          Directory of 5 Million Amazon Sellers
                        </Text>
                      </StatLabel>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Evaluate Amazon seller performance by category and marketplace
                      </Text>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Benchmark your business against the competition
                      </Text>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Category specific seller rankings
                      </Text>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Rankings across 12 global marketplaces
                      </Text>
                    </Flex>
                  </Stat>
                </Flex>
              </CardBody>
            </Card>
            <Card className={styles.AboutCard}>
              <Box className="circleIcon"></Box>
              <CardBody>
                <Flex
                  direction={{
                    base: "column-reverse",
                    md: "column-reverse",
                    lg: "row",
                  }}
                  align="start"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <Flex
                      alignItems={{ base: "start", md: "center", lg: "center" }}
                      mb={{ base: "15px", md: "20px", lg: "30px" }}
                      direction={{ base: "column", md: "row", lg: "row" }}
                    >
                      <IconBox
                        w={{
                          md: "80px",
                          lg: "100px",
                          xl: "100px",
                          "2xl": "100px",
                        }}
                      >
                        <Image src={sellersChromeExtensionIcon} alt="hero" />
                      </IconBox>
                      <StatLabel
                        className={styles.CardStatLabel}
                        paddingLeft={{ base: "0px", md: "30px", lg: "30px" }}
                      >
                        <Heading
                          as={"h3"}
                          color="#243e63"
                          mb={{ base: "5px", md: "10px", lg: "10px" }}
                          mt={{ base: "20px", lg: "0px" }}
                          fontSize={{
                            base: "1.5rem",
                            md: "1.4rem",
                            lg: "1.4rem",
                          }}
                          align="left"
                        >
                          Supplier Success Solutions
                        </Heading>

                        <Text className={styles.CardStatNumber}>
                          For Distributors & Manufacturers
                        </Text>
                      </StatLabel>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Connect with top-performing Amazon sellers
                      </Text>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Establish strategic partnerships
                      </Text>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Optimize product offerings and pricing
                      </Text>
                    </Flex>
                    <Flex className={styles.CardPoints}>
                      <CheckIcon
                        color="orange.300"
                        className={styles.CardPointIcons}
                      />
                      <Text color="gray.500" className={styles.CardPointText}>
                        Expand reach within the Amazon ecosystem
                      </Text>
                    </Flex>
                  </Stat>
                </Flex>
              </CardBody>
            </Card>
          </SimpleGrid>
        </Container>
      </Box>
      <Box pt="30px" pb="30px" className={styles.BgImage}>
        <Container maxW="container.xl">
          <Heading
            as={"h3"}
            fontSize={{ base: "2.8rem", md: "3rem", lg: "3rem" }}
            className={styles.PlatformSectionBoxHeading}
            mb="10"
          >
            Data-Driven Insights for Amazon Marketplace Stakeholders
          </Heading>
          <Box>
            <Flex
              display={{ base: "flex", md: "flex", lg: "flex" }}
              direction={{ base: "column", md: "column", lg: "row" }}
            >
              <Box flex="1" mb={{ base: "20px", md: "50px" }}>
                <Accordion
                  allowToggle
                  defaultIndex={0}
                  padding={{ base: "0px", md: "0px 20px", lg: "0px 40px" }}
                  onChange={(Index) => {
                    parseInt(Index) != -1 &&
                      setCurrentAccordianIndex(parseInt(Index))
                  }}
                >
                  <AccordionItem
                    mx={{ base: 1, md: 5, lg: 0 }}
                    className={styles.PlatformAccordionItem}
                  >
                    <h2 m={0}>
                      <AccordionButton
                        _active={{
                          background: "transparent",
                        }}
                        _focus={{
                          outline: "none",
                          boxShadow: "none",
                          background: "#fff",
                        }}
                        _hover={{
                          background: "#fff",
                        }}
                        className={styles.PlatformAccordionButton}
                      >
                        <Box flex="1" textAlign="left">
                          <Heading
                            as={"h3"}
                            color="#243e63"
                            m="0px"
                            fontSize={{
                              base: "1.3rem",
                              md: "1rem",
                              lg: "1.4rem",
                            }}
                            align="left"
                            fontWeight="600"
                          >
                            Amazon Seller Leads
                          </Heading>
                        </Box>
                        <AccordionIcon
                          className={styles.PlatformAccordionIcon}
                        />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Box
                        fontSize={{
                          base: "1.2rem",
                          md: "1.025rem",
                          lg: "1.1rem",
                        }}
                        lineHeight="1.6"
                        color="rgb(103, 119, 136)"
                      >
                        Unlock the full potential of Amazon seller lead generation
                        with our comprehensive database and advanced search features
                      </Box>
                      <Box
                        color="#243e63"
                        mt="15px"
                        display="block"
                        textAlign="right"
                      >
                        <NextLink href="/seller-leads" passHref>
                          <Link
                            transition=" all 0.8s ease-in-out"
                            fontSize="0.875rem"
                            lineHeight="1.75"
                            padding="15px"
                            borderRadius="7px"
                            background="#fff"
                            _hover={{
                              transition: " all 0.5s ease-in-out",
                              background: "#eef5fa",
                            }}

                          >
                            Learn More
                          </Link>
                        </NextLink>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem className={styles.PlatformAccordionItem}>
                    <h2 m={0}>
                      <AccordionButton
                        _active={{
                          background: "transparent",
                        }}
                        _focus={{
                          outline: "none",
                          boxShadow: "none",
                          background: "#fff",
                        }}
                        _hover={{
                          background: "#fff",
                        }}
                        className={styles.PlatformAccordionButton}
                      >
                        <Box flex="1" textAlign="left">
                          <Heading
                            as={"h3"}
                            color="#243e63"
                            m="0px"
                            fontSize={{
                              base: "1.3rem",
                              md: "1rem",
                              lg: "1.4rem",
                            }}
                            align="left"
                            fontWeight="600"
                          >
                            Amazon Seller Intelligence Report
                          </Heading>
                        </Box>
                        <AccordionIcon
                          className={styles.PlatformAccordionIcon}
                        />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Box
                        fontSize={{
                          base: "1.2rem",
                          md: "1.025rem",
                          lg: "1.1rem",
                        }}
                        lineHeight="1.6"
                        color="rgb(103, 119, 136)"
                      >
                        Gain insights into any Amazon seller&apos;s business,
                        metrics, and product listings to make data-driven
                        decisions and stay ahead of the competition
                      </Box>
                      <Box
                        color="#243e63"
                        mt="15px"
                        display="block"
                        textAlign="right"
                      >
                        <NextLink href="/seller-intelligence-report" passHref>
                          <Link
                            transition=" all 0.8s ease-in-out"
                            fontSize="0.875rem"
                            lineHeight="1.75"
                            padding="15px"
                            borderRadius="7px"
                            background="#fff"
                            _hover={{
                              transition: " all 0.5s ease-in-out",
                              background: "#eef5fa",
                            }}

                          >
                            Learn More
                          </Link>
                        </NextLink>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem className={styles.PlatformAccordionItem}>
                    <h2 m={0}>
                      <AccordionButton
                        _active={{
                          background: "transparent",
                        }}
                        _focus={{
                          outline: "none",
                          boxShadow: "none",
                          background: "#fff",
                        }}
                        _hover={{
                          background: "#fff",
                        }}
                        className={styles.PlatformAccordionButton}
                      >
                        <Box flex="1" textAlign="left">
                          <Heading
                            as={"h3"}
                            color="#243e63"
                            m="0px"
                            fontSize={{
                              base: "1.3rem",
                              md: "1rem",
                              lg: "1.4rem",
                            }}
                            align="left"
                            fontWeight="600"
                          >
                            Amazon Top Seller Lists
                          </Heading>
                        </Box>
                        <AccordionIcon
                          className={styles.PlatformAccordionIcon}
                        />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Box
                        fontSize={{
                          base: "1.2rem",
                          md: "1.025rem",
                          lg: "1.1rem",
                        }}
                        lineHeight="1.6"
                        color="rgb(103, 119, 136)"
                      >
                        Stay ahead in the competitive Amazon marketplace
                        with our comprehensive and accurate seller ranking
                        system across 12 domains
                      </Box>
                      <Box
                        color="#243e63"
                        mt="15px"
                        display="block"
                        textAlign="right"
                      >
                        <NextLink href={`/sellers/by-domain/${domainContextValue.code}`} passHref>
                          <Link
                            transition=" all 0.8s ease-in-out"
                            fontSize="0.875rem"
                            lineHeight="1.75"
                            padding="15px"
                            borderRadius="7px"
                            background="#fff"
                            _hover={{
                              transition: " all 0.5s ease-in-out",
                              background: "#eef5fa",
                            }}

                          >
                            Learn More
                          </Link>
                        </NextLink>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem className={styles.PlatformAccordionItem}>
                    <h2 m={0}>
                      <AccordionButton
                        _active={{
                          background: "transparent",
                        }}
                        _focus={{
                          outline: "none",
                          boxShadow: "none",
                          background: "#fff",
                        }}
                        _hover={{
                          background: "#fff",
                        }}
                        className={styles.PlatformAccordionButton}
                      >
                        <Box flex="1" textAlign="left">
                          <Heading
                            as={"h3"}
                            color="#243e63"
                            m="0px"
                            fontSize={{
                              base: "1.3rem",
                              md: "1rem",
                              lg: "1.4rem",
                            }}
                            align="left"
                            fontWeight="600"
                          >
                            Amazon Top Seller Lists by Category
                          </Heading>
                        </Box>
                        <AccordionIcon
                          className={styles.PlatformAccordionIcon}
                        />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Box
                        fontSize={{
                          base: "1.2rem",
                          md: "1.025rem",
                          lg: "1.025rem",
                        }}
                        lineHeight="1.6"
                        color="rgb(103, 119, 136)"
                      >
                        Uncover the most relevant competition and find
                        potential leads with our unique, category-specific
                        seller rankings
                      </Box>
                      <Box
                        color="#243e63"
                        mt="15px"
                        display="block"
                        textAlign="right"
                      >
                        <NextLink href="/top-sellers-by-category" passHref>
                          <Link
                            transition=" all 0.8s ease-in-out"
                            fontSize="0.875rem"
                            lineHeight="1.75"
                            padding="15px"
                            borderRadius="7px"
                            background="#fff"
                            _hover={{
                              transition: " all 0.5s ease-in-out",
                              background: "#eef5fa",
                            }}

                          >
                            Learn More
                          </Link>
                        </NextLink>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
              <Box flex="1" mt={0}>
                <Card
                  p={0}
                  borderRadius="4px"
                  border="1px solid #e5e7eb"
                  overflow="hidden"
                  w="100%"
                >
                  <Image
                    src={accordionImageList[currentAccordianIndex]}
                    alt="hero"
                  />
                </Card>
              </Box>
            </Flex>
          </Box>
        </Container>
      </Box>
      <Box bg="#fff" pt="30px" pb="30px">
        <Container maxW="container.xl">
          <Heading
            color="#243e63"
            fontSize={{ base: "2.8rem", md: "3rem", lg: "3rem" }}
            align="center"
            mb="10"
          >
            Features
          </Heading>
          <Box pt="10px" pb="10px" w="100%">
            <Card
              padding={{ base: "1.5rem 1rem", md: "2rem", lg: "2rem" }}
              bg="#ffd6c1"
              border="none"
              w="100%"
              boxShadow="0 2px 5px rgba(0,0,0,.13)"
              borderRadius="15px"
              data-aos="zoom-in"
              overflow="hidden"
              margin="10px 0px"
            >
              <Flex
                alignItems="center"
                mb={{ base: "0px", md: "0px" }}
                display={{ base: "flex", md: "flex", lg: "flex" }}
                direction={{ base: "column", md: "column", lg: "row" }}
              >
                <Box
                  flex="1"
                  padding={{ base: "0rem", md: "2rem", lg: "2rem" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    w={{ base: "100%", md: "100%", lg: "80%" }}
                    m="auto"
                    mb={{ base: "30px", md: "0px" }}
                  >
                    <Heading
                       as={"h3"}
                      color="#243e63"
                      fontSize={{ base: "1.6rem", md: "3rem", lg: "3rem" }}
                      mb="10px"
                    >
                      Advanced Seller Search
                    </Heading>
                    <Text
                      w="100%"
                      color="#0a2153"
                      fontSize="1.2rem"
                      fontWeight="400"
                    >
                      Unleash the full potential of your Amazon seller
                      lead generation with our cutting-edge search capabilities
                    </Text>
                  </Box>
                </Box>
                <Box flex="1" w="100%">
                  <Card
                    p={3}
                    borderRadius="20px"
                    border="none"
                    w="100%"
                    minH={{ base: "250px", md: "350px", lg: "400px" }}
                    h="100%"
                    overflow="hidden"
                  >
                    <Box
                      className={styles.SmallBox}
                      width="100%"
                      m={3}
                      overflow="hidden"
                      textAlign="center"
                    >
                      <Image
                        // boxSize={{ lg: "100%", xl: "300px", "2xl": "300px" }}
                        src={advancedSellerSearch}
                        alt="advancedSellerSearch"
                        padding="40px"
                        objectFit="none"
                      />
                    </Box>
                  </Card>
                </Box>
              </Flex>
            </Card>
          </Box>
          <Box pt="10px" pb="10px">
            <Card
              padding={{ base: "1.5rem 1rem", md: "2rem", lg: "2rem" }}
              bg="#c0ddff"
              border="none"
              w="100%"
              boxShadow="0 2px 5px rgba(0,0,0,.13)"
              borderRadius="15px"
              data-aos="zoom-in"
              overflow="hidden"
              margin="10px 0px"
            >
              <Flex
                alignItems="center"
                mb={{ base: "0px", md: "0px" }}
                display={{ base: "flex", md: "flex", lg: "flex" }}
                direction={{ base: "column-reverse", md: "column", lg: "row" }}
              >
                <Box flex="1">
                  <Card
                    p={5}
                    borderRadius="20px"
                    border="none"
                    w="100%"
                    minH={{ base: "250px", md: "350px", lg: "400px" }}
                    h="100%"
                    overflow="hidden"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      className={styles.SmallBox}
                      width="100%"
                      m={0}
                      overflow="hidden"
                    >
                      <Image
                        // boxSize={{ lg: "100%", xl: "300px", "2xl": "300px" }}
                        src={featuresDashboard}
                        alt="hero"
                        padding="40px"
                        objectFit="contain"
                      />
                    </Box>
                  </Card>
                </Box>
                <Box
                  flex="1"
                  padding={{ base: "0rem", md: "2rem", lg: "2rem" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    w={{ base: "100%", md: "100%", lg: "80%" }}
                    m="auto"
                    mt={{ base: "0px", md: "0px" }}
                    mb={{ base: "30px", md: "0px" }}
                  >
                    <Heading
                       as={"h3"}
                      color="#243e63"
                      fontSize={{ base: "1.6rem", md: "3rem", lg: "3rem" }}
                      mb="10px"
                    >
                      Seller Dashboard
                    </Heading>
                    <Text
                      w="100%"
                      color="#0a2153"
                      fontSize="1.2rem"
                      fontWeight="400"
                      lineHeight="1.6"
                    >
                      In-depth seller profiles with key business metrics,
                      category insights, brand listings, and review history
                    </Text>
                  </Box>
                </Box>
              </Flex>
            </Card>
          </Box>
          <Box pt="10px" pb="10px">
            <Card
              padding={{ base: "1.5rem 1rem", md: "2rem", lg: "2rem" }}
              bg="#ffd6c1"
              border="none"
              w="100%"
              boxShadow="0 2px 5px rgba(0,0,0,.13)"
              borderRadius="15px"
              data-aos="zoom-in"
              overflow="hidden"
              margin="10px 0px"
            >
              <Flex
                alignItems="center"
                mb={{ base: "0px", md: "0px" }}
                display={{ base: "flex", md: "flex", lg: "flex" }}
                direction={{ base: "column", md: "column", lg: "row" }}
              >
                <Box
                  flex="1"
                  padding={{ base: "0rem", md: "2rem", lg: "2rem" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    w={{ base: "100%", md: "100%", lg: "80%" }}
                    m="auto"
                    mb={{ base: "30px", md: "0px" }}
                  >
                    <Heading
                       as={"h3"}
                      color="#243e63"
                      fontSize={{ base: "1.6rem", md: "3rem", lg: "3rem" }}
                      mb="10px"
                    >
                      Seller Ranking
                    </Heading>
                    <Text
                      w="100%"
                      color="#0a2153"
                      fontSize="1.2rem"
                      fontWeight="400"
                    >
                      Rankings across 12 Amazon marketplaces and
                      corresponding categories, offering valuable
                      insights into performance variations in diverse markets
                    </Text>
                  </Box>
                </Box>
                <Box flex="1">
                  <Card
                    p={5}
                    borderRadius="20px"
                    border="none"
                    w="100%"
                    minH={{ base: "250px", md: "350px", lg: "400px" }}
                    h="100%"
                    overflow="hidden"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      className={styles.SmallBox}
                      width="100%"
                      m={0}
                      overflow="hidden"
                    >
                      <Image
                        // boxSize={{ lg: "100%", xl: "300px", "2xl": "300px" }}
                        src={featuresRanking}
                        alt="hero"
                        padding="40px"
                        objectFit="contain"
                      />
                    </Box>
                  </Card>
                </Box>
              </Flex>
            </Card>
          </Box>
        </Container>
      </Box>
      {/** Subscription */}
      <ModalIframe
        show={subscribeModalShow}
        close={subscribeModalToggle}
        heading={heading}
        iframeUrl={iframeUrl}
        height={height}
      />
    </Fragment>
  )
}